<template>
  <content-card-full>
    <template #title>
      <card-title>
        <template #title><h2 class="title">Investment Buy List</h2></template>
      </card-title>
    </template>
    <template #content-full>
      <div class="flex">
        <!-- LEFT -->
        <div class="flex">
          <div class="flex flex-column justify-content-between">
            <div>
              <h3 class="mb-3">Introduction</h3>
              <div class="pr-5">
                <div class="card-body">All funds on our buy list are selected and monitored by our in-house investment committee, using our rigorous research and screening methodology. These funds are used to make up our model portfolios, as well as for clients who wish to manage their own investments.</div>
                <div class="card-body">Our aim is to offer a good range covering all main markets, along with some more niche funds to enhance diversification, whilst avoiding ‘choice overload’. </div>
                <div class="card-body">For each market sector covered, our buy list also includes ethical and/or sustainable investment funds, which have been subject to an additional layer of screening based on environmental, social and governance (ESG) criteria.</div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="flex" style="width: 50%;">
          <img src="@/assets/img/Video.png" alt="" style="width: 100%; aspect-ratio: 1.8">
        </div>
        -->
      </div>

      <spacer-h />

      <div>
        <h3 class="mb-3">Investment Buy List</h3>
        <div class="card-body">This buy list covers unit trusts and OEICs, which are available for direct investments, as well as through individual savings accounts (ISAs). The full buy list is subject to a thorough review every quarter, with additional ad-hoc reviews of specific funds or sectors should there be material changes or issues. For example, a change in manager or concerns over performance. To avoid unnecessary levels of switching, we look for longevity and stability as part of our selection process. You can read more about our investment research methodology here.</div>
        <div class="flex"><Icon name="leaf-icon" style="opacity: 1"></Icon><div class="card-body ml-3">Where a fund has a leaf icon next to its name, this means it meets our ESG criteria.</div></div>
      </div>

      <spacer-h />
      <div v-if="group && group.name === 'pwc'">
          <div class="warningMessage">
              <div class="flex justify-content-between">
                  <div class="flex">
                      <h3 class="orange"><span class="material-icons">warning</span>Please Note</h3>
                  </div>
              </div>
              <p class="body-text">For clients who are restricted in their investment options we advised that they pre-clear the funds above with their relevant authority before making investments into them. We cannot guarantee they are all permissible.</p>
          </div>
      </div>

      <spacer-h />

      <div>
        <BuyList v-if="!loadingBuylist" :list="buylist" :group="group.name" />
        <div v-if="loadingBuylist">
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
            <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
            <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
      </div>

      <spacer-h />

      <div class="clarity-margin-top-m">
        <h3 class="mb-3">Funds on hold</h3>
        <div class="card-body">This list contains the funds that have been moved from a buy status to a “hold” status. We are no longer recommending clients buy these funds, for various reasons specific to the fund itself and it’s market, but we see no reason at present to recommend these funds be sold.</div>
        <div class="card-body">This list is kept under review, and any changes will be notified in the usual way via our email alert system.</div>

        <spacer-h />

        <HoldList v-if="!loadingBuylist" :list="holdlist"/>
        <div v-if="loadingBuylist">
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-2" height="2rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
          <Skeleton class="mb-3" width="12rem" borderRadius="16px"></Skeleton>
        </div>
      </div>

    </template>
  </content-card-full>
</template>
<script>
import api from "@/core/services/api";
import {onMounted, ref, reactive, computed} from "vue";
import {toDrupalRequest} from "@/core/helpers";
import {useStore} from "vuex";
import BuyList from "@/components/research/BuyList";
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import HoldList from "@/components/research/HoldList";
import Icon from '@/components/common/Icon'
import SpacerH from '@/components/common/layout/SpacerH.vue'

export default {
  setup() {
    onMounted(() => {
      getData()
    })

    const store = useStore()
    const buylist = ref([])
    const holdlist = ref([])
    const loadingBuylist = ref(true)
    const buylisttype = computed(() => store.getters.getBuyList)
    const group = reactive({
      name: '',
      complianceNotes: []
    })

    const getData = async () => {
      let endpoint = `/buylist/full`
      if (buylisttype.value && buylisttype.value !== 'clarity') {
        endpoint += '/'+buylisttype.value.toLowerCase()
      }
      const response = await api.get(`${toDrupalRequest(endpoint)}`)
      if(response.error) {
        await store.dispatch('addNotification', {severity: 'error', summary: 'Set Default Portfolio', detail: `'Unable to get buylist`})
      }
      if (response.data) {
        group.name = response.data.group
        group.complianceNotes = response.data.compliancenotes
        response.data.buylist.forEach(entry => {
          if (entry.buylistComment === 'Hold') {
            holdlist.value.push(entry)
          }
          if (entry.buylistComment !== 'Hold') {
            buylist.value.push(entry)
          }
        })
      }
      loadingBuylist.value = false
    }

    return {
      buylist,
      holdlist,
      group,
      loadingBuylist
    }
  },
  components: {
    BuyList,
    CardTitle,
    ContentCardFull,
    HoldList,
    Icon,
    SpacerH
  }
}
</script>
<style scoped>
.card-body {
  font-size: 18px;
  padding: 5px 0px;
}
.button-cta {
  display: flex;
  margin: 40px 0px;
  justify-content: flex-start;
}
.button-cta button {
  padding: 15px 45px;
}
.superscript {
  position: relative;
  top: -0.5em;
  font-size: 80%;
}
.compliance-note {
  font-size: 18px;
}
.warningMessage {
    background: transparent linear-gradient(180deg, #FFBA6233 0%, #F189034D 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #0000000D;
    border: 1px solid #F1890380;
    border-radius: 27px;
    margin-bottom: 30px;
    padding: 32px 30px 35px 30px;
}
.warningMessage p {
    margin-left: 35px;
    margin-bottom: 0px;
}
</style>
