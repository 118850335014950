<template>
  <div>
    <div class="buylist-filter-toggle">
      <div class="buylist-filter">
        <span class="p-input-icon-left cl-input full-width">
          <i class="pi pi-search"></i>
          <InputText type="text"  v-model="filters.global.value" placeholder="Looking for a fund..." class="p-inputtext-lg w-full" />

        </span>
      </div>
      <div class="buylist-toggle">
        <div>Show: <Icon name="leaf-icon" /><span :class="[ isSelected ? '' : 'selected-option']">only ESG Funds</span></div>
        <InputSwitch v-model="showAllFunds" @click="toggleEsgOnly"/>
        <div :class="[ isSelected ? 'selected-option': '']">All Funds</div>
      </div>
    </div>
    <DataTable v-if="list" :value="list" class="rd-table" v-model:filters="filters" ::globalFilterFields="['fundName', 'isin', 'sedol', 'sectorDescription']">
      <Column field="fundName" header="Fund Name" sortable>
        <template #body="slotProps">
          <div class="flex">
            <h3 class="light-blue">{{slotProps.data.fundName}}</h3><span v-if="slotProps.data.esg"><Icon name="leaf-icon"/></span>
          </div>
        </template>
      </Column>
      <Column field="isin" header="ISIN" sortable></Column>
      <Column field="sectorDescription" header="Sector" sortable></Column>
      <template #empty>
        <div>No Funds to display</div>
      </template>
    </DataTable>
  </div>

</template>

<script>
import {FilterMatchMode} from "primevue/api";
import {computed, ref} from "vue";
import Icon from  '@/components/common/Icon'

export default {
  name: "HoldList",
  props: ['list'],
  setup() {
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'esg': {value: null, matchMode: FilterMatchMode.EQUALS}
    })

    const showAllFunds = ref(true)

    const toggleEsgOnly = () => {
      showAllFunds.value = !showAllFunds.value
      filters.value.esg.value = showAllFunds.value === true ? showAllFunds.value = null : showAllFunds.value = true
    }

    const isSelected = computed(() => {
      return showAllFunds.value
    })

    return {
      filters,
      showAllFunds,
      toggleEsgOnly,
      isSelected
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.icon {
  opacity: 1;
}
.selected-option {
  font-weight: bold;
}
.rd-table {
  font-size: 18px;
}
</style>