<template>
  <div>
    <!-- SEARCH -->
    <div class="buylist-filter-toggle">
      <div class="buylist-filter">
        <span class="cl-input p-input-icon-left full-width">
          <i class="pi pi-search"></i>
          <InputText type="text"  v-model="filters.global.value" placeholder="Looking for a fund..." class="p-inputtext-lg w-full" />

        </span>
      </div>
      <div class="buylist-toggle">
        <div>Show: <Icon name="leaf-icon" /><span :class="[ isSelected ? '' : 'selected-option']">only ESG Funds</span></div>
        <InputSwitch v-model="showAllFunds" @click="toggleEsgOnly"/>
        <div :class="[ isSelected ? 'selected-option': '']">All Funds</div>
      </div>
    </div>
      <DataTable
          v-if="list"
          :value="list"
          class="rd-table"
          rowGroupMode="subheader"
          groupRowsBy="sectorDescription"
          sortField="sectorDescription"
          sortMode="single" :sortOrder="1"
          scrollable
          scrollHeight="700px"
          id="buylist"
          :globalFilterFields="['fundName', 'isin', 'sedol', 'sectorDescription']"
          v-model:filters="filters"
      >
        <Column class="fundNameCol" field="fundName" header="Fund Name" sortable>
          <template #body="slotProps">
            <div class="flex">
              <h3 class="light-blue">{{slotProps.data.fundName}}</h3>
              <span v-if="slotProps.data.esg"><Icon name="leaf-icon"/></span>
            </div>
          </template>
        </Column>
        <Column class="isinCol" field="isin" header="ISIN" sortable></Column>
        <Column class="sedolCol" field="sedol" header="SEDOL" sortable></Column>
        <Column class="amcCol" field="amc" sortable>
          <template #header>
            <span class="p-column-title pr-2">AMC</span>
            <span class="material-icons-round white" v-tooltip.top="'Annual Management Charge (AMC) this is the explicit charge levied by the fund manager'">info</span>
          </template>
          <template #body="slotProps">
            {{ $nb(slotProps.data.amc, 'percentage') }}
          </template>
        </Column>
        <Column class="ocfCol" field="ocf" sortable>
          <template #header>
            <span class="p-column-title pr-2">OCF</span>
            <span class="material-icons-round white" v-tooltip.top="'Ongoing Charge Figure (OCF) is the total cost of running the fund, which includes the AMC as well as any expenses'">info</span>
          </template>
          <template #body="slotProps">
            {{ $nb(slotProps.data.ocf, 'percentage') }}
          </template>
        </Column><br>
        <Column class="actionsCol" field="actions" header="Actions">
          <template #body="slotProps">
            <div class="actions">
              <div class="doc-links">
                <a class="kidCol" :href="getKID(slotProps.data.isin)" target="_blank"><div v-tooltip.top="'Download PDF file'" class="doc-link"><Icon name="pdf-icon" />KIID</div></a>
                <a class="buyNoteCol" :href="getBuyNote(slotProps.data.isin)" target="_blank"><div v-tooltip.top="'Download PDF file'" class="doc-link"><Icon name="pdf-icon" />Research Note</div></a>
              </div>
              <Button class="investNowBtn clarity-btn clarity-gradient-orange" @click="loadInvestNow(slotProps.data.isin)">Invest Now</Button>
            </div>
          </template>
        </Column>
        <template #groupheader="slotProps">
          <div :id="`rowGroupHeader-${slotProps.data.sectorDescription}`" class="subheader" v-html="slotProps.data.sectorDescription"></div>
        </template>
        <template #footer>
        </template>
        <template #empty>
          <div>No Funds to display</div>
        </template>
      </DataTable>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {FilterMatchMode} from "primevue/api";
import Icon from  '@/components/common/Icon'

export default {
  name: "BuyList",
  props: ['list', 'group'],
  setup() {
    const router = useRouter();
    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      'esg': {value: null, matchMode: FilterMatchMode.EQUALS}
    })
    const clarityURL = process.env.VUE_APP_CLARITYURL;


    const showAllFunds = ref(true)

    const toggleEsgOnly = () => {
      showAllFunds.value = !showAllFunds.value
      filters.value.esg.value = showAllFunds.value === true ? showAllFunds.value = null : showAllFunds.value = true
    }

    const getKID = (isin) => {
      return `${clarityURL}/sites/default/files/buylist/${isin}-kiid.pdf`
    }

    const getBuyNote = (isin) => {
      return `${clarityURL}/sites/default/files/buylist/${isin}-research-note.pdf`
    }

    const isSelected = computed(() => {
      return showAllFunds.value
    })

    const loadInvestNow = () => {
      router.push('/investments/add/');
    }

    return {
      getKID,
      getBuyNote,
      filters,
      showAllFunds,
      toggleEsgOnly,
      isSelected,
      loadInvestNow
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
img {
  opacity: 1;
}
.rd-table:deep(.p-rowgroup-header):first-of-type,
.rd-table:deep(.p-rowgroup-header){
  font-weight: bold;
  background: transparent linear-gradient(180deg, var(--clarity-snow-grey) 0%, var(--clarity-light-grey) 100%) 0% 0% no-repeat padding-box;
}
.rd-table:deep(.p-rowgroup-header){
  border-top: 2px solid var(--clarity-pure-white);
}
.rd-table {
  font-size: 18px;
}
.rd-table:deep(.p-datatable-footer){
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
  background: transparent linear-gradient(180deg, var(--clarity-snow-grey) 0%, var(--clarity-light-grey) 100%) 0% 0% no-repeat padding-box;
  border-bottom: 1px solid var(--clarity-light-grey);
  padding: 5px;
}
.rd-table:deep(.p-datatable-table tr th) {
  padding: 20px 10px !important;
}
.p-datatable:deep(.p-datatable-tbody > tr > td) {
  padding: 20px 10px !important;
}
.selected-option {
  font-weight: bold;
}
.doc-links {
  display: flex;
  align-items: center;
}
.doc-link {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: var(--clarity-dark-grey);
}
.doc-link:hover {
  color: var(--clarity-blue);
  cursor: pointer;
}
.doc-link img {
    filter: invert(13%) sepia(3%) saturate(2094%) hue-rotate(171deg) brightness(98%) contrast(90%);
}
.doc-link:hover img {
    filter: invert(15%) sepia(78%) saturate(4473%) hue-rotate(198deg) brightness(95%) contrast(101%);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.actions button {
  padding: 20px 45px;
}
.superscript {
  position: relative;
  top: -0.5em;
  font-size: 80%;
}
:deep(.fundNameCol) {
  min-width: 30%;
}
:deep(.isinCol) {
  min-width: 12%;
}
:deep(.sedolCol) {
  min-width: 10%;
}
:deep(.amcCol) {
  min-width: 10%;
}
:deep(.ocfCol) {
  min-width: 10%;
}
:deep(.actionsCol) {
  min-width: 25%;
}

@media only screen and (max-width: 1920px) {
  :deep(.fundNameCol) {
    min-width: 25%;
  }
  :deep(.isinCol) {
    min-width: 15%;
  }
  :deep(.sedolCol) {
    min-width: 10%;
  }
  :deep(.amcCol) {
    min-width: 5%;
  }
  :deep(.ocfCol) {
    min-width: 5%;
  }
  :deep(.actionsCol) {
    min-width: 35%;
  }
}
@media only screen and (max-width: 1366px) {
  :deep(.sedolCol) {
    display: none !important;
  }
  h3 {
    font-size: 16px;
  }
  .rd-table {
    font-size: 16px;
  }
  .clarity-btn {
    font-size: 16px !important;
    min-width: 140px;
  }
  .actions button {
    padding: 11px 27px;
  }
  :deep(.fundNameCol) {
    min-width: 20%;
  }
  :deep(.isinCol) {
    min-width: 17%;
  }
  :deep(.amcCol) {
    min-width: 5%;
  }
  :deep(.ocfCol) {
    min-width: 5%;
  }
  :deep(.actionsCol) {
    min-width: 40%;
  }
  :deep(.actionsCol th) {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  :deep(.amcCol) {
    display: none !important;
  }
  :deep(.fundNameCol) {
    min-width: 20%;
  }
  :deep(.isinCol) {
    min-width: 15%;
  }
  :deep(.ocfCol) {
    min-width: 5%;
  }
  :deep(.actionsCol) {
    min-width: 40%;
  }
}

@media only screen and (max-width: 1000px) {
  :deep(.isinCol) {
    display: none !important;
  }
  :deep(.ocfCol) {
    max-width: 20%;
  }
  :deep(.fundNameCol) {
    max-width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  :deep(.kidCol) {
    display: none !important;
  }
  :deep(.investNowBtn) {
    display: none !important;
  }
  :deep(.p-sortable-column-icon) {
    display: none !important;
  }

}

@media only screen and (max-width: 375px) {
  :deep(.ofcCol) {
    display: none !important;
  }
  :deep(.buyNoteCol Img) {
    display: none !important;
  }
}
</style>
